import React, { ReactElement, useEffect, useState } from 'react'
import Link from '@components/core/link'
import { useLocation } from '@reach/router'
import {
  AppBar as DefaultAppBar,
  Toolbar as DefaultToolbar,
  Grid,
  styled,
  Container as DefaultContainer,
} from '@mui/material'
import MetaNavigation from '../metaNavigation'

import DefaultLogo from '@static/img/logos/logo.svg'
import DefaultTriangle from '@static/img/icons/triangle.svg'

import { missingOptionalPropsCheck } from '@system/utils/missingOptionalPropsCheck'
interface KeyboardEvent {
  key: string
}

type NavItems = {
  contentful_id: string
  titleNavigation?: string
  fields: {
    fullPath: string
  }
}

export type HeaderProps = {
  small: boolean
  navigationItems: NavItems[]
  metaNavigationItems: NavItems[]
}

export const HEADER_HEIGHT = {
  S: 60,
  L_SCROLL: 68,
  L: 116,
}

type NavTypes = {
  open: boolean
}

type ScrollTypes = {
  small: string
}

const AppBar = styled(DefaultAppBar)<ScrollTypes>(({ small, theme }) => ({
  height: `${HEADER_HEIGHT.S}px`,
  justifyContent: 'center',
  transition: `height 300ms, background ${
    small === 'false' ? '300ms' : '0s'
  }  !important`,

  [theme.breakpoints.down('lg')]: {
    background: theme.palette.common.white,
  },

  [theme.breakpoints.up('lg')]: {
    background:
      small === 'false' || theme.darkTheme
        ? theme.palette.common.white
        : 'transparent',
    transition: 'height 400ms',
    height:
      small === 'false'
        ? `${HEADER_HEIGHT.L_SCROLL}px`
        : `${HEADER_HEIGHT.L}px`,
  },
}))

const Toolbar = styled(DefaultToolbar)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
}))

const NavIconWrapper = styled('div')({
  position: 'relative',
})

const NavIcon = styled('div')<NavTypes>(({ theme, open }) => ({
  width: '44px',
  height: '44px',
  position: 'relative',
  zIndex: 1301,
  cursor: 'pointer',

  '> span, &:after, &::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    right: 0,
    height: '2px',
    borderRadius: '1px',
    background: open ? theme.palette.common.white : theme.palette.common.black,
    transition: 'transform 300ms, width 300ms, background 300ms',

    [theme.breakpoints.down('lg')]: {
      width: open ? '23px' : '20px',
    },

    [theme.breakpoints.up('lg')]: {
      width: open ? '45px' : '20px',
    },
  },

  '> span': {
    transition: open ? 'opacity 0ms' : 'opacity 300ms',
    width: '28px',
    opacity: open ? 0 : 1,
  },

  '&:after': {
    [theme.breakpoints.down('lg')]: {
      transform: open
        ? 'translateX(1px) translateY(0px) rotate(45deg)'
        : 'translateY(-6px)',
    },

    [theme.breakpoints.up('lg')]: {
      transform: open
        ? 'translateX(6px) translateY(0px) rotate(45deg)'
        : 'translateY(-6px)',
    },
  },

  '&:before': {
    [theme.breakpoints.down('lg')]: {
      transform: open
        ? 'translateX(1px) translateY(0px) rotate(-45deg)'
        : 'translateY(6px)',
    },

    [theme.breakpoints.up('lg')]: {
      transform: open
        ? 'translateX(6px) translateY(0px) rotate(-45deg)'
        : 'translateY(6px)',
    },
  },
}))

const Logo = styled(DefaultLogo)<ScrollTypes>(({ small, theme }) => ({
  width: 'auto',
  height: '46px',

  [theme.breakpoints.up('lg')]: {
    transition: 'height 400ms',
    height: small === 'false' ? '52px' : '96px',
  },
}))

const NavigationLayer = styled('nav')<NavTypes>(({ theme, open }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: theme.palette.common.blue,
  zIndex: open ? 1300 : -1,
  opacity: open ? 1 : 0,
  visibility: open ? 'visible' : 'hidden',
  transition: 'opacity 300ms, visibility 300ms, z-index 300ms',
}))

const NavigationContainer = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  padding: 0,
  margin: 0,
  listStyle: 'none',

  [theme.breakpoints.down('lg')]: {
    padding: '24px',
  },
}))

const Container = styled(DefaultContainer)({
  height: '100%',
})

const ToolbarContainer = styled(DefaultContainer)({
  padding: '0 !important',
})

const NavLinkBig = styled(Link)<{ highlight: boolean }>(
  ({ theme, highlight }) => ({
    color: theme.palette.common.white,
    fontSize: '32px',
    fontWeight: 900,
    lineHeight: 1,
    padding: '18px 0',
    backgroundPosition: '0% calc(100% - 14px)',
    fontFamily: theme.typography.fontFamilyBlack,

    [theme.breakpoints.up('lg')]: {
      padding: '24px 0',
      fontSize: '64px',
      opacity: highlight ? 1 : 0.7,
    },
  })
)

const MetaNavigationWrapper = styled('div')(({ theme }) => ({
  marginTop: '48px',

  [theme.breakpoints.up('lg')]: {
    marginTop: '32px',
  },
}))

const Triangle = styled(DefaultTriangle)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  width: 'auto',
  zIndex: '-1',

  [theme.breakpoints.down('lg')]: {
    height: '60vh',
    transform: 'translate(0%, 10%)',
  },

  [theme.breakpoints.up('lg')]: {
    height: '130vh',
    transform: 'translate(0%, -20%)',
  },

  '> path': {
    fill: theme.palette.common.green,
  },
}))

export default function Header({
  small,
  navigationItems,
  metaNavigationItems,
}: HeaderProps): ReactElement {
  const [open, setOpen] = useState(false)
  const [hoverIdx, setHoverIdx] = useState<number | null>(null)
  const location = useLocation()

  function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      setOpen(!open)
    }
  }

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        setOpen(false)
      }
    }

    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [])

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [open])

  return (
    <AppBar
      small={small.toString()}
      elevation={0}
      position="fixed"
      color="transparent"
    >
      <Toolbar>
        <ToolbarContainer maxWidth="xl">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link to="/" noHover>
              <Logo small={small.toString()} />
            </Link>

            <NavIconWrapper>
              <Grid container direction="row" alignItems="center" gap={10}>
                <div id="filter-layer"></div>
                <NavIcon
                  tabIndex={0}
                  open={open}
                  onClick={() => setOpen(!open)}
                  onKeyDown={onKeyDown}
                >
                  <span />
                </NavIcon>
              </Grid>
            </NavIconWrapper>
          </Grid>
        </ToolbarContainer>
      </Toolbar>
      <NavigationLayer open={open}>
        <Container>
          <NavigationContainer aria-label="Main">
            {navigationItems.map((item, i) => {
              missingOptionalPropsCheck(
                { titleNavigation: item.titleNavigation },
                'Modules | Global | Header | navigationItems',
                item.contentful_id
              )
              return (
                <li key={i}>
                  {item.titleNavigation && (
                    <NavLinkBig
                      to={item.fields.fullPath}
                      onClick={() => setOpen(false)}
                      active={
                        typeof window !== 'undefined' &&
                        location &&
                        item.fields.fullPath === location.pathname
                      }
                      highlight={
                        hoverIdx === null ||
                        (typeof i === 'number' && i === hoverIdx)
                      }
                      onMouseEnter={() => setHoverIdx(i)}
                      onMouseLeave={() => setHoverIdx(null)}
                    >
                      {item.titleNavigation}
                    </NavLinkBig>
                  )}
                </li>
              )
            })}
            <MetaNavigationWrapper>
              <MetaNavigation
                navigationItems={metaNavigationItems}
                closeNavigation={() => setOpen(false)}
              />
            </MetaNavigationWrapper>
          </NavigationContainer>
        </Container>
        <Triangle />
      </NavigationLayer>
    </AppBar>
  )
}
